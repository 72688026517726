/**
 * Get all the URL params
 * @returns {Object} All query params from URL
 */
export const getUrlParams = () => {
  if (typeof window === 'undefined') return {}

  const params = new URLSearchParams(window.location.search)
  const parsedParams = {}

  for (const [key, value] of params.entries()) {
    parsedParams[key] = value
  }

  return parsedParams
}

/**
 * Prioritize a specific field for a specific affiliate
 * @param {Object} params - Query params object
 * @param {string} prioritedFieldName - Field to prioritize
 * @param {string} targetAffiliate - Target affiliate to prioritize the field for
 * @returns {Object} Query params object with prioritized field
 */
export const prioritizeAffiliateParams = (params, prioritedFieldName, targetAffiliate) => {
  let finalParams = {}
  const { [prioritedFieldName]: prioritedField, utm_source: utmSource, ...otherParams } = params
  if (utmSource === targetAffiliate) {
    finalParams = {
      ...(prioritedField && { [prioritedFieldName]: prioritedField }),
      utm_source: utmSource,
      ...otherParams,
    }
  } else {
    finalParams = params
  }
  return finalParams
}

const getPrioritizedOptions = (params) => {
  let customOptions = {}
  if (params.utm_source === 'creditkarma_lightbox') {
    customOptions = {
      prioritizedField: 'segment',
      targetAffiliate: 'creditkarma_lightbox',
    }
  }
  return customOptions
}
/**
 * Attach the current query params to a URL
 * @param {string} url - URL to attach query params to
 * @returns {string} URL with query params attached
 */
export const attachCurrentQueryParams = (url) => {
  let queryParams = getUrlParams()
  if (Object.keys(queryParams).length > 0) {
    const customOptions = getPrioritizedOptions(queryParams)
    const { prioritizedField, targetAffiliate } = customOptions
    // If prioritizedField is provided, prioritize the field for specific affiliate
    if (prioritizedField) {
      queryParams = prioritizeAffiliateParams(queryParams, prioritizedField, targetAffiliate)
    }
    const urlParams = new URLSearchParams(queryParams)
    url = `${ url }?${ urlParams.toString() }`
  }
  return url
}

/**
 * Get the URL for the Check My Rate button
 * @param {boolean} newFunnelFlag - Flag to determine if the new funnel is being used
 * @returns {string} URL for the Check My Rate page
 */
export const NEW_FUNNEL_URL = `${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_WEB_URL }/create-account`

export const getCheckMyRateUrl = () => {
  return attachCurrentQueryParams(NEW_FUNNEL_URL)
}

export default {
  getUrlParams,
  getCheckMyRateUrl,
}
