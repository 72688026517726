import { meta } from '@happymoneyinc/meta'

import { icons } from './icons'

export const theme = {
  breakpoints: {
    xs: '320px',
    xs1: '550px',
    sm: '768px',
    md: '1024px',
    md1: '1025px',
    lg: '1200px',
    xl: '1440px',
    xxl: '1920px',
    xxxl: '2450px',
  },
  colors: {
    azureLight: '#51C6FF', // TODO: add to @happymoneyinc/meta
    azureDark: '#0096DF', // TODO: add to @happymoneyinc/meta
    cream: '#FAF4EE', // TODO: add to @happymoneyinc/meta
    flamingoDark: '#F975B4', // TODO: add to @happymoneyinc/meta
    flamingoDarker: '#E11A67', // TODO: add to @happymoneyinc/meta
    gold: '#86754B',
    lightGray: '#F5F6F7', // TODO: add to @happymoneyinc/meta
    limeGreen: '#11E07C', // TODO: add to @happymoneyinc/meta
    yellow: '#FDFA38',  // TODO: add to @happymoneyinc/meta,
    goldenHour: '#FFD28F',  // TODO: add to @happymoneyinc/meta,
    ...meta.colors,
  },
  fonts: {
    GTAmericaMedium: '\'gt-america-medium\', Sans-Serif',
    GTAmericaRegular: '\'gt-america-regular\', Sans-Serif',
    InterBold: '\'inter-bold\', Sans-Serif',
    InterMedium: '\'inter-medium\', Sans-Serif',
    InterRegular: '\'inter-regular\', Sans-Serif',
    MabryProBold: '\'mabry-pro-bold\', Sans-Serif',
    MabryProMedium: '\'mabry-pro-medium\', Sans-Serif',
    MabryProRegular: '\'mabry-pro-regular\', Sans-Serif',
    MabryProLight: '\'mabry-pro-light\', Sans-Serif',
    MabryMonoProRegular: '\'mabry-mono-pro-regular\', Sans-Serif',
    MabryMonoProBold: '\'mabry-mono-pro-bold\', Sans-Serif',
    MabryMonoProMedium: '\'mabry-mono-pro-medium\', Sans-Serif',
    PPObjectSansBlack: '\'pp-object-sans-black\', Sans-Serif',
    PPObjectSansBold: '\'pp-object-sans-bold\', Sans-Serif',
    PPObjectSansHeavy: '\'pp-object-sans-heavy\', Sans-Serif',
    PPObjectSansMedium: '\'pp-object-sans-medium\', Sans-Serif',
    PPObjectSansRegular: '\'pp-object-sans-regular\', Sans-Serif',
  },
  icons: {
    ...icons
  },
  display: {
    large: {
      fontSizeMin: 60,
      fontSizeMax: 110,
      lineHeight: 1.15,
      letterSpacing: -0.015
    },
    medium: {
      fontSizeMin: 48,
      fontSizeMax: 72,
      lineHeight: 1.2,
      letterSpacing: -0.015
    },
    small: {
      fontSizeMin: 32,
      fontSizeMax: 56,
      lineHeight: 1.2,
      letterSpacing: -0.015
    },
    xsmall: {
      fontSizeMin: 38,
      fontSizeMax: 48,
      lineHeight: 1.2,
      letterSpacing: -0.015
    }
  },
  headline: {
    xxlarge: {
      fontSizeMin: 32,
      fontSizeMax: 48,
      lineHeight: 1.2,
      letterSpacing: -0.015,
    },
    xlarge: {
      fontSizeMin: 23.5,
      fontSizeMax: 44,
      lineHeight: 1.25,
      letterSpacing: -0.01,
    },
    large: {
      fontSizeMin: 26,
      fontSizeMax: 32,
      lineHeight: 1.5,
      letterSpacing: -0.005,
    },
    medium: {
      fontSizeMin: 18,
      fontSizeMax: 24,
      lineHeight: 1.5,
      letterSpacing: -0.005
    },
    small: {
      fontSizeMin: 16,
      fontSizeMax: 18,
      lineHeight: 1.5,
      letterSpacing: -0.005
    }
  },
  quote: {
    large: {
      fontSizeMin: 32,
      fontSizeMax: 56,
      lineHeight: 1.5,
      letterSpacing: -0.01
    },
    medium: {
      fontSizeMin: 20,
      fontSizeMax: 42,
      lineHeight: 1.5,
      letterSpacing: -0.015
    },
    small: {
      fontSizeMin: 18,
      fontSizeMax: 32,
      lineHeight: 1.5,
      letterSpacing: 0
    }
  },
  text: {
    xlarge: {
      fontSizeMin: 20,
      fontSizeMax: 26,
      fontFamily: '\'mabry-pro-regular\', Sans-Serif',
      lineHeight: 1.5,
      letterSpacing: -0.015,
    },
    large: {
      fontSizeMin: 15,
      fontSizeMax: 16.5,
      fontFamily: '\'mabry-pro-regular\', Sans-Serif',
      lineHeight: 1.6,
      letterSpacing: 0
    },
    button: {
      fontSizeMin: 14.5,
      fontSizeMax: 17.5,
      fontFamily: '\'mabry-pro-regular\', Sans-Serif',
      lineHeight: 1,
      letterSpacing: 0.01
    },
    eyebrow: {
      fontSizeMin: 16,
      fontSizeMax: 18,
      fontFamily: '\'mabry-pro-medium\', Sans-Serif',
      lineHeight: 1.25,
      letterSpacing: 0.02
    },
    small: {
      fontSizeMin: 12,
      fontSizeMax: 14,
      fontFamily: '\'inter-medium\', Sans-Serif',
      lineHeight: 1.5,
      letterSpacing: 0
    },
    buttonEyebrow: {
      fontSizeMin: 24,
      fontSizeMax: 32,
      fontFamily: '\'mabry-pro-regular\', Sans-Serif',
      lineHeight: 1.2,
      letterSpacing: -0.015
    },
    xsmall: {
      fontSizeMin: 8,
      fontSizeMax: 12,
      fontFamily: '\'inter-medium\', Sans-Serif',
      lineHeight: 1.5,
      letterSpacing: 0
    }
  },
}

export default theme
