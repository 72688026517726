import { css } from 'styled-components'
import { fluidRange } from 'polished'

export const displaySmallStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-weight: 400;
  line-height: ${ ({ theme }) => theme.display.small.lineHeight };
  letter-spacing: ${ ({ theme }) => `${ theme.display.small.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.display.small.fontSizeMin }px`,
        toSize: `${ theme.display.small.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
`

export const headlineXXLargeStyles = css`
  font-family: ${ ({ theme }) => theme.fonts.PPObjectSansBold };
  font-weight: 400;
  line-height: ${ ({ theme }) => theme.headline.xxlarge.lineHeight };
  letter-spacing: ${ ({ theme }) => `${ theme.headline.xxlarge.letterSpacing }em` };

  ${ ({ theme }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.headline.xxlarge.fontSizeMin }px`,
        toSize: `${ theme.headline.xxlarge.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
`

export const headlineLargeStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.headline.large.lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.headline.large.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.headline.large.fontSizeMin }px`,
        toSize: `${ theme.headline.large.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
`

export const headlineMediumStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.headline.medium.lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.headline.medium.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.headline.medium.fontSizeMin }px`,
        toSize: `${ theme.headline.medium.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
`

export const headlineSmallStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.headline.small.lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.headline.small.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.headline.small.fontSizeMin }px`,
        toSize: `${ theme.headline.small.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
`

export const textLargeStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  line-height: ${ ({ theme, size }) => theme.text.large.lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.text.large.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.text.large.fontSizeMin }px`,
        toSize: `${ theme.text.large.fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

export const textSmallStyles = css`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  line-height: ${ ({ theme }) => theme.text.small.lineHeight };
  letter-spacing: ${ ({ theme }) => `${ theme.text.small.letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.text.small.fontSizeMin }px`,
        toSize: `${ theme.text.small.fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

export const cmsGeneralStyles = css`
  strong {
    font-family: ${ ({ theme }) => `${ theme.fonts.MabryProBold }` };
  }

  img {
    max-width: 100%;
  }

  ul {
    padding: 0 0 0 25px;
    margin: 0 0 60px;
  }

  a {
    color: ${ ({ theme }) => theme.colors.black };
    font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
    text-decoration: underline;

    &::after {
      content: none;
    }

    &:hover,
    &:focus {
      color: ${ ({ theme }) => theme.colors.gray[1] };
    }
  }
  
  .mb-0 {
    display: block;
    margin-bottom: 0 !important;
  }

  .mb-10 {
    display: block;
    margin-bottom: 10px !important;
  }

  .mb-20 {
    display: block;
    margin-bottom: 20px !important;
  }

  .mb-30 {
    display: block;
    margin-bottom: 30px !important;
  }

  .mb-40 {
    display: block;
    margin-bottom: 40px !important;
  }

  .mb-50 {
    display: block;
    margin-bottom: 50px !important;
  }

  .mb-60 {
    display: block;
    margin-bottom: 60px !important;
  }

  .mb-80 {
    display: block;
    margin-bottom: 80px !important;
  }

  .table {
    width: 100%;

    th,
    td {
      padding: 15px 22px;
      vertical-align: middle;

      @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
        padding: 30px 40px;
      }
    }

    td,
    p,
    li {
      font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;
    }

    h2,
    ul {
      margin-top: 0;
    }

    &.table-bordered {
      margin: 0 0 52px;
      box-shadow: 0 1px 1px ${ ({ theme }) => theme.colors.black };
      border: solid ${ ({ theme }) => theme.colors.black } 1px;
      border-collapse: separate;
      border-top: none;
      border-bottom: none;
      border-spacing: 0;
      border-radius: 10px;

      td {
        text-align: center;
        border-top: 1px solid ${ ({ theme }) => theme.colors.black };
        border-bottom: none;
        border-left: 1px solid ${ ({ theme }) => theme.colors.black };

        &:first-child {
          text-align: left;
          border-left: none;
          border-radius: 0 0 0 10px;
        }

        &:last-child {
          border-radius: 0 0 10px;
        }
      }

      th {
        text-align: center;
        border-top: none;
        border-top: 1px solid ${ ({ theme }) => theme.colors.black };
        border-left: 1px solid ${ ({ theme }) => theme.colors.black };

        &:first-child {
          border-left: none;
          border-radius: 10px 0 0;
        }

        &:last-child {
          border-radius: 0 10px 0 0;
        }

        &:only-child {
          border-radius: 10px 10px 0 0;
        }
      }

      tr {
        &:first-child {
          border-radius: 0 0 0 10px;
        }

        &:last-child {
          border-radius: 0 0 10px;
        }
      }
    }
  }
`

export const cmsArticleStyles = css`
  ${ cmsGeneralStyles }

  h2 {
    ${ headlineMediumStyles }
  }

  h3 {
    ${ headlineSmallStyles }
  }

  h4 {
    font-weight: normal;
  }

  p {
    ${ textLargeStyles }

    margin-top: 0;

    &:not(.labeled-child) {
      margin-bottom: 40px;
    }
  }

  li {
    ${ textLargeStyles }

    margin-top: 0;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`

export const cmsPrivacyStyles = css`
  ${ cmsGeneralStyles }

  h2 {
    ${ headlineLargeStyles }

    ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'margin-top',
        fromSize: '50px',
        toSize: '80px',
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
  }

  h2:first-child {
    margin-top: 0;
  }

  p {
    /* regular p element */
    ${ headlineSmallStyles }

    ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'margin-bottom',
        fromSize: '20px',
        toSize: '40px',
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }

    /* medium p element */
    &.medium {
      ${ headlineMediumStyles }

      margin: 0;
    }

    /* eyebrow p element */
    &.eyebrow {
      margin: 0;
      font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
      line-height: ${ ({ theme, size }) => theme.text.eyebrow.lineHeight };
      letter-spacing: ${ ({ theme, size }) => `${ theme.text.eyebrow.letterSpacing }em` };

      ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.text.eyebrow.fontSizeMin }px`,
        toSize: `${ theme.text.eyebrow.fontSizeMax }px`,
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`,
    ) }
    }
  }

  li {
    ${ headlineSmallStyles }

    margin-bottom: 5px;
  }

  .flex {
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    gap: 24px 18px;

    @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
      flex-direction: row;
    }

    h2 {
      margin: 0;

      @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
        width: 25%;
      }
    }

    &.flex-bordered {
      box-sizing: border-box;
      padding: 24px;
      margin-bottom: 80px;
      border: 1px solid ${ ({ theme }) => `${ theme.colors.black }` };
      border-radius: 5px;
    }

    &.column {
      flex-direction: column;

      h2 {
        width: auto;
      }
    }

    .flex-right {
      flex: 2;
    }

    p,
    li {
      ${ textLargeStyles }

      margin: 0;

      &.medium {
        ${ headlineMediumStyles }

        @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
          width: 25%;
        }
      }
    }
  }

  .underline {
    text-decoration: underline;
  }
`

export default {
  cmsGeneralStyles,
  cmsArticleStyles,
  cmsPrivacyStyles,
  textLargeStyles,
}
