export const NON_API_AFFILIATE_PATH = '/affiliates/[nonAPIAffiliateUID]'
export const API_AFFILIATE_PATH = '/partner/[applicationId]'

// For nonAPIAffiliateUID in path /affiliate/[nonAPIAffiliateUID]
// To add additional non api affiliate page, add the uid to the NON_API_AFFILIATE_LIST
export const NON_API_AFFILIATE_LIST = ['credit-karma', 'credit-karma-lightbox', 'nerdwallet', 'commission-junction', 'perkspot', 'truebill']
// prismic document "template variable" Id
export const TEMPLATE_VARIABLES_ID = 'Zcr-axEAAC4A9oiv'

// Pages with "revalidate" enabled will automatcic regenerate every 24 hours after build and initially requested
export const REVALIDATE_TIME_INTERVAL = 60 * 60 * 24

// For Header and Footer conditionally rendering
export const LANDING_PATHS = ['/[landingPageUID]', '/invite', '/buildup', '/giftcard', '/purpose', NON_API_AFFILIATE_PATH, API_AFFILIATE_PATH]
